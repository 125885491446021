// Polyfill per Array.prototype.at
if (!Array.prototype.at) {
	Array.prototype.at = function(index) {
		index = index >= 0 ? index : this.length + index;
		return this[index];
	};
}

// Polyfill per String.prototype.at
if (!String.prototype.at) {
	String.prototype.at = function(index) {
		index = index >= 0 ? index : this.length + index;
		return this.charAt(index);
	};
}
